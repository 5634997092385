/* Donation Card Styles */
.content {
  text-align: center;
}

.donation-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.donation-card {
  width: calc(25% - 20px);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative; /* Ensure relative positioning for absolute child */
}

@media (max-width: 768px) {
  .donation-card {
    width: 100%; /* Full width on smaller screens */
    margin-right: 0; /* Remove right margin */
  }
}

.card-content {
  padding: 20px;
  flex-grow: 1;
}

.card-content h3 {
  margin-top: 0;
  color: #333;
}

.card-content p {
  color: #666;
}

.card-image {
  height: 200px;
  background-position: top; /* Fixed height for the image */
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-image img {
  width: 100%; /* Ensure image fills container width */
  height: 100%; /* Ensure image fills container height */
  object-fit: cover; /* Maintain aspect ratio and fill container */
}

.donate-button {
  position: absolute; /* Position the button */
  bottom: 0; /* Align to the bottom of the card */
  width: 100%; /* Full width */
  text-align: center;
  padding: 10px;
}

.donate-button button {
  padding: 10px 20px;
  background-color: #3c00e0;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.donate-button button:hover {
  background-color: #2e0080;
}
