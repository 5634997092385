.piracy-policy-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Set minimum height for full viewport coverage */
  background-color: #f5f5f5; /* Light background color */
  padding: 2rem;
}

.piracy-policy-content {
  margin: auto;
  max-width: 90%;
  padding: 2rem;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

h1 {
  text-align: center;
  font-size: 5rem;
  margin-bottom: 1rem;
}

p,
ul {
  line-height: 1.5;
}

ul {
  list-style: disc;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}
